import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`vTaiwan`}</h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` A mass movement demanded upgrades to democracy and won, resulting in Polis' first national-scale deployment`}<br parentName="p"></br>{`
`}<strong parentName="p">{`When:`}</strong>{` 2014 - ongoing`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Where:`}</strong>{` Taiwan`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Size:`}</strong>{` 200,000+  `}</p>
    <p><strong parentName="p">{`Details:`}</strong>{` The mass protests of the 2014 Sunflower Movement publicly demonstrated that better group decision-making was possible through facilitation and demanded the government of Taiwan upgrade their democratic processes accordingly. The government conceded, and requested that the g0v (pronounced "gov zero") civic tech community create a scalable listening process for national use. The resulting multiphase, design thinking-informed, collective intelligence process became known as vTaiwan ("v" for virtual), and today vTaiwan contains the longest running, national-scale implementation of Polis. Accordingly, Taiwan has generated more case studies on using Polis in the process of creating national legislation than any other country.`}</p>
    <p><strong parentName="p">{`Overview:`}</strong>{` `}</p>
    <p>{`vTaiwan is an open consultation process that brings citizens and government together in online and offline spaces to deliberate and reach rough consensus on national issues in order to create national digital legislation. The first test of vTaiwan was in August 2015 on how to regulate the ride sharing service Uber. By strategically focusing on issues which have an online component, such as online sales of regulated substances or the digital distribution of "revenge porn" images, the vTaiwan process carved its own lane through issues that had stalled or gidlocked ordinary legislative processes. Fast facts to know about vTaiwan: 1) vTaiwan is run by PDIS - Taiwan's Public Digital Innovation Service. 2) Participation by staffers at agencies with the domain to change policy based on the results of the consultation is mandatory. 3) The staffers receive a special training in how civil servants can participate in the process, after which they gain the title "Participation Officer." 4) Polis is only one of the open source softwares used in the vTaiwan process, and there are also in-person components. PDIS has continued to innovate on several additional fronts, for instance, creating a platform called JOIN to enable petition-fueled agenda setting on which greater than 50% of Taiwan's entire population has interacted, an extremely high rate of uptake. `}</p>
    <h3>{`Links`}</h3>
    <p>{`Although the following list is long and contains multiple links to the Uber case, this is but a small fraction of all the coverage there has been on Taiwan's Public Digital Innovation Service that runs the vTaiwan process, and on the use of Polis within vTaiwan. Find our recommendations in `}<strong parentName="p">{`bold:`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Government of Taiwan website:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://info.vtaiwan.tw/"
        }}>{`https://info.vtaiwan.tw/`}</a>{`  `}</li>
      <li parentName="ul"><strong parentName="li">{`2022 Video by Audrey Tang`}</strong>{`, Taiwan's Digital Minister, recounting the origin story of vTaiwan and Polis (at minute 8:40)
`}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=ESW6D59eFuw"
        }}>{`https://www.youtube.com/watch?v=ESW6D59eFuw`}</a>{`   `}</li>
      <li parentName="ul">{`⭐️ `}<strong parentName="li">{`2016 article in Civicist `}<a parentName="strong" {...{
            "href": "https://web.archive.org/web/20161024093003/http://civichall.org/civicist/vtaiwan-democracy-frontier/"
          }}>{`article`}</a>{` by `}<a parentName="strong" {...{
            "href": "https://twitter.com/lizbarry"
          }}>{`Liz Barry`}</a></strong>{`  `}</li>
      <li parentName="ul"><strong parentName="li">{`2018 process overview paper by Yu-Tang Hsiao, Shu-Yang Lin, Audrey Tang, Darshana Narayanan, Claudina Sarahe:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://osf.io/preprints/socarxiv/xyhft/"
        }}>{`https://osf.io/preprints/socarxiv/xyhft/`}</a>{`  `}</li>
      <li parentName="ul"><strong parentName="li">{`Video by Christian Svanes`}</strong>{`: "Designing For Trust: vTaiwan and The Vanguards for Crowd-Sourced Democracy" `}<a parentName="li" {...{
          "href": "https://vimeo.com/259324525/4cd1218115"
        }}>{`https://vimeo.com/259324525/4cd1218115`}</a>{`   `}</li>
      <li parentName="ul"><strong parentName="li">{`WIRED`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://www.wired.co.uk/article/taiwan-democracy-social-media"
        }}>{`https://www.wired.co.uk/article/taiwan-democracy-social-media`}</a>{`  `}</li>
      <li parentName="ul"><strong parentName="li">{`MIT Technology Review`}</strong>{` `}<a parentName="li" {...{
          "href": "https://www.technologyreview.com/2018/08/21/240284/the-simple-but-ingenious-system-taiwan-uses-to-crowdsource-its-laws/"
        }}>{`https://www.technologyreview.com/2018/08/21/240284/the-simple-but-ingenious-system-taiwan-uses-to-crowdsource-its-laws/`}</a>{`  `}</li>
      <li parentName="ul"><strong parentName="li">{`The Atlantic`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://www.theatlantic.com/magazine/archive/2021/04/the-internet-doesnt-have-to-be-awful/618079/"
        }}>{`https://www.theatlantic.com/magazine/archive/2021/04/the-internet-doesnt-have-to-be-awful/618079/`}</a>{` `}</li>
      <li parentName="ul"><strong parentName="li">{`BBC`}</strong>{` article `}<a parentName="li" {...{
          "href": "https://www.bbc.com/news/technology-50127713"
        }}>{`https://www.bbc.com/news/technology-50127713`}</a></li>
      <li parentName="ul"><strong parentName="li">{`CPI`}</strong>{` `}<a parentName="li" {...{
          "href": "https://www.centreforpublicimpact.org/case-study/building-consensus-compromise-uber-taiwan/"
        }}>{`https://www.centreforpublicimpact.org/case-study/building-consensus-compromise-uber-taiwan/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/@shuyanglin/how-to-start-something-like-vtaiwan-in-your-country-7fb9399e8339"
        }}>{`https://medium.com/@shuyanglin/how-to-start-something-like-vtaiwan-in-your-country-7fb9399e8339`}</a>{` `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://blog.pol.is/digital-tools-open-up-taiwans-democratic-imaginations-d8f80432305c"
        }}>{`https://blog.pol.is/digital-tools-open-up-taiwans-democratic-imaginations-d8f80432305c`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/@richdecibels/how-taiwan-solved-the-uber-problem-29fd2358a284"
        }}>{`https://medium.com/@richdecibels/how-taiwan-solved-the-uber-problem-29fd2358a284`}</a></li>
      <li parentName="ul">{`Polis' blog on the Uber case: `}<a parentName="li" {...{
          "href": "https://blog.pol.is/uber-responds-to-vtaiwans-coherent-blended-volition-3e9b75102b9b"
        }}>{`https://blog.pol.is/uber-responds-to-vtaiwans-coherent-blended-volition-3e9b75102b9b`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Uber Report — `}<a parentName="li" {...{
              "href": "https://pol.is/report/r32beaksmhwesyum6kaur"
            }}>{`https://pol.is/report/r32beaksmhwesyum6kaur`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.citymetric.com/business/how-taiwan-inoculating-itself-against-uber-virus-2786"
        }}>{`https://www.citymetric.com/business/how-taiwan-inoculating-itself-against-uber-virus-2786`}</a></li>
      <li parentName="ul">{`Audrey Tang presentation on the Uber case 2016-01-19: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=qbdvvStfWT0"
        }}>{`https://www.youtube.com/watch?v=qbdvvStfWT0`}</a></li>
      <li parentName="ul">{`HBR: `}<a parentName="li" {...{
          "href": "https://digital.hbs.edu/platform-rctom/submission/vtaiwan-crowdsourcing-legislation-in-technology-and-beyond/"
        }}>{`https://digital.hbs.edu/platform-rctom/submission/vtaiwan-crowdsourcing-legislation-in-technology-and-beyond/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://g0v.tw/"
        }}>{`https://g0v.tw/`}</a>{` Motto: Fork The Government :)`}</li>
    </ul>
    <h3><strong parentName="h3">{``}<a parentName="strong" {...{
          "href": "/talkto.tw",
          "title": "talkto.tw"
        }}>{`talkto.tw`}</a>{``}</strong></h3>
    <p>{`A previous related project, now deprecated: `}</p>
    <ul>
      <li parentName="ul">{`Coverage: `}<a parentName="li" {...{
          "href": "https://monocle.com/magazine/issues/96/full-focus/"
        }}>{`https://monocle.com/magazine/issues/96/full-focus/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://talkto.tw/"
        }}>{`http://talkto.tw/`}</a></li>
      <li parentName="ul">{`"talkto.tw behind the scenes: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=94BKI6Wfz2Y%22"
        }}>{`https://www.youtube.com/watch?v=94BKI6Wfz2Y"`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      